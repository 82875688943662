import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  faAddressCard,
  faBullseyeArrow,
  faChartMixed,
  faChartNetwork,
  faChartUser,
  faJetFighter,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import _ from "lodash";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import backgroundImage from "../../images/headers/investors-about.png";
import Hero from "../../components/Hero";
import CustomizedIcon from "../../components/CustomizedIcon";
import InterestedItems from "../../components/InterestedItems";
import { colors } from "../../theme";
import strategyImage from "../../images/investors/strategy.png";
// import businessModalImage1 from "../../images/investors/business-modal-1.png"
import businessModalImage1 from "../../images/investors/one-stop-shop.png";
import businessModalImage2 from "../../images/investors/business-modal-2.png";
import { headers, restHeaders } from "../../utils/navbar";
import QuarterlyFigures from "../../components/QuarterlyFigures";

const iconsContent = () => [
  {
    title: "Sustained market share gain",
    description: `
        Continued market share gain and perpetuating the flywheel effect.
        Allfunds has a proven record in developing business activities in its
        existing markets and outside its core markets, successfully growing
        international market share.
      `,
    icon: faChartMixed,
    color: colors.blue.main,
  },
  {
    title: "Expansion of digital subscription-based business",
    description: `
        Allfunds’ digital value-added proposition is a key pillar of its
        strategy to build a fully integrated, one-stop shop B2B wealth
        management marketplace.
      `,
    icon: faAddressCard,
    color: "#9B9B9B",
  },
  {
    title: "Perpetuating the flywheel effect",
    description: `
        Complemented by the Allfunds flywheel effect, the number of Fund Houses
        increases, so does the value of Allfunds’ platform proposition to
        Distributors, and vice versa.
      `,
    icon: faJetFighter,
    color: colors.red.main,
  },
  {
    title: "Margin Resilience",
    description: `
        Prime positioned to compensate margin fee pressure given the global
        scale and reach, strong relationships with both Distributors and Fund
        Houses in addition to its independence give Allfunds the ability to
        negotiate prices.
      `,
    icon: faChartNetwork,
    color: colors.red.main,
  },
  {
    title: "Operating efficiencies via scale",
    description: `
        Thanks to its scalable platform, and the continued investments to
        improve it, Allfunds is able to onboard new Distributors at very low
        marginal costs.
      `,
    icon: faChartUser,
    color: colors.blue.main,
  },
  {
    title: "Focus on M&A",
    description: `
        Proven M&A capabilities with a demonstrable track record of successful
        acquisitions have helped accelerate growth and platform enhancement.
      `,
    icon: faBullseyeArrow,
    color: "#9B9B9B",
  },
];

function InvestorsAboutPage() {
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.share_information") },
    { ..._.get(restHeaders(), "about.elements.about_team") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.financial_reports") },
  ];

  return (
    <NewLayout apps="investors.investors_about">
      <Hero
        title="Shaping the next generation WealthTech platform"
        buttonText="Allfunds Financial Reports"
        buttonPath="/investors/financial/financial-reports"
        backgroundImage={backgroundImage}
        kind="About us"
      />
      <Container py={8}>
        <Box>
          <Typography mb={2} variant="h3">
            About Allfunds
          </Typography>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: { xs: "240px", sm: "500px" },
                  borderRadius: "5px",
                  backgroundImage: `url(${strategyImage})`,
                  backgroundSize: "cover",
                  filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.305882))",
                }}
              />
            </Grid>
            <StyledGrid item xs={12} md={6}>
              <Box>
                <Typography variant="h3">Allfunds at a glance</Typography>
                <Typography>
                  Allfunds operates an open architecture platform which provides
                  a marketplace connecting financial institutions wanting to buy
                  and/or distribute funds – either for their own account, for
                  products they manage (including funds of funds or pension
                  funds) or on behalf of their clients – (all these entities
                  referred to together as Distributors) with asset managers that
                  launch, manage or distribute such funds (referred to as Fund
                  Houses).
                </Typography>
              </Box>
              <Box mt={{ xs: 2, sm: 4 }}>
                <Typography variant="h3">Strategy</Typography>
                <Typography>
                  Allfunds believes that its competitive strengths have allowed
                  it to be at the forefront of innovation and to take full
                  advantage of favorable market trends, evolving from Allfunds
                  1.0, a European platform with limited service offering, to
                  Allfunds 2.0 a one-stop shop. The group believes it is well
                  positioned to enhance its business and increase scale over the
                  coming years, with opportunities mostly centering on the
                  following strategic pillars supporting the Allfunds 3.0 vision
                  for the future as a fully digital client service provider.
                </Typography>
              </Box>
            </StyledGrid>
          </Grid>
        </Box>
        <Box sx={{ mt: { xs: 5, md: 10 } }}>
          <QuarterlyFigures kind="about" />
        </Box>
        <Box mt={{ xs: 5, sm: 10 }}>
          <Typography mb={{ xs: 2, sm: 4 }} variant="h3">
            Strategic Pillars
          </Typography>
          <Grid container spacing={{ xs: 2, sm: 4 }}>
            {iconsContent().map(({ title, description, icon, color }) => (
              <Grid item xs={12} sm={6} md={4}>
                <CustomizedIcon
                  icon={icon}
                  color={color}
                  title={title}
                  description={description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={{ xs: 5, sm: 10 }}>
          <Typography mb={{ xs: 2, sm: 4 }} variant="h3">
            Business Model
          </Typography>
          <Grid container spacing={{ xs: 2, sm: 4 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography>
                Allfunds operates within the wealth management value chain.
                Underpinning the value proposition of the Allfunds Platform is
                Allfunds Connect, a SaaS-enabled, subscription-based portal
                through which Distributors and Fund Houses have access to a
                variety of modular digital tools. This integrated, one-stop shop
                ecosystem provides Allfunds with a competitive advantage over
                other market participants, who typically only provide a sub-set
                of services available on the Allfunds Platform.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img alt="first_image" src={businessModalImage1} style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography>
                  Allfunds believes that it has a simple and attractive business
                  model. Distributors benefit from a buy-free model of core
                  services related to trading, dealing, custody, settlement and
                  administration while paying for other value-added services.
                  Fund Houses benefit from an attractive value-for-money
                  proposition in which they pay a fee to Allfunds for the
                  intermediated and distributed AuA plus other value-added
                  services.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img alt="second_image" src={businessModalImage2} style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
        </Box>
        <Box pt={{ xs: 4, sm: 0 }}>
          <InterestedItems items={interestedItems} />
        </Box>
      </Container>
    </NewLayout>
  );
}

const StyledGrid = styled(Grid)`
  > p,
  ul > li > p {
    font-size: 16px;
  }
`;

export default InvestorsAboutPage;
